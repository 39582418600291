import React, { useState } from "react";
import ReactDOM from "react-dom";
import { PageHeader, Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import MainLogo from "../../../static/Home/main-logo.png";
import HeaderLogo from "../../../static/Home/logo-white.png";
import "antd/dist/antd.css";

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      menuOpen: false,
    };
  }
  showSettings(event) {
    event.preventDefault();
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }
  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu
        noOverlay
        right
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <a
          onClick={() => this.closeMenu()}
          href="#about-us"
          className="mobile-display"
          style={{
            color: "white",
            marginTop: "1.6em",
            marginRight: "2em",
            border: "none",
            fontWeight: 500,
            boxShadow: "none",
            fontSize: "17px",
          }}
        >
          About Us
        </a>
        <a
          onClick={() => this.closeMenu()}
          href="#medical-care"
          className="mobile-display"
          style={{
            color: "white",
            marginTop: "1.6em",
            marginRight: "2em",
            border: "none",
            fontWeight: 500,
            boxShadow: "none",
            fontSize: "17px",
          }}
        >
          Medical Aid
        </a>
        <a href="/#book" className="mobile-display">
          <Button
            onClick={() => this.closeMenu()}
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              marginLeft: "-0.1em",
              width: "10em",
              fontSize: "17px",
              backgroundColor: "#00AEE9",
              border: "black",
              marginTop: "2em",
            }}
            type="primary"
          >
            Get a Callback
          </Button>
        </a>
        <a href="https://medcaresolutions.co.za/medcare-admin/" className="mobile-display">
          <Button
            onClick={() => this.closeMenu()}
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              marginLeft: "-0.1em",
              width: "3em",
              fontSize: "17px",
              backgroundColor: "#00AEE9",
              border: "black",
              marginTop: "2em",
            }}
            type="primary"
          >
            <UserOutlined />
          </Button>
        </a>
      </Menu>
    );
  }
}
const MainHeader = () => (
  <div id="Header" className="site-page-header-ghost-wrapper">
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "95em",
        width: "90vw",
        margin: "auto",
        padding: 0,
        paddingTop: "2em",
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to="/">
            <img
              className="header-logo"
              style={{ width: "11em" }}
              src={HeaderLogo}
            />
          </Link>
          <div class="main">
            <div class="circle" />
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[
        <a
          href="#about-us"
          className="mobile-display"
          style={{
            color: "#3D3D3D",
            marginTop: "1.6em",
            marginRight: "2em",
            border: "none",
            fontWeight: 500,
            boxShadow: "none",
            fontSize: "17px",
          }}
        >
          About Us
        </a>,
        <a
          href="#medical-care"
          className="mobile-display"
          style={{
            color: "#3D3D3D",
            marginTop: "1.6em",
            marginRight: "2em",
            border: "none",
            fontWeight: 500,
            boxShadow: "none",
            fontSize: "17px",
          }}
        >
          Medical Aid
        </a>,
        //<Button
        //  style={{
        //    color: "black",
        //    marginTop: "1.6em",
        //    border: "none",
        //    boxShadow: "none",
        //    fontWeight: 500,
        //    fontSize: "17px",
        //  }}
        //>
        //  <Dropdown overlay={menu}>
        //    <a
        //      className="ant-dropdown-link"
        //      onClick={(e) => e.preventDefault()}
        //    >
        //      Products <DownOutlined />
        //    </a>
        //  </Dropdown>
        //</Button>,
        // <a
        //   href="/#book"
        //   className="mobile-display"
        //   style={{
        //     color: "white",
        //     marginTop: "1.6em",
        //     marginRight: "1em",
        //     border: "none",
        //     boxShadow: "none",
        //     fontWeight: 500,
        //     fontSize: "17px",
        //   }}
        // >
        //   Medical Aid
        // </a>,
        <a href="/#book" className="mobile-display">
          <Button
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              marginLeft: "0.5em",
              width: "10em",
              fontSize: "17px",
              backgroundColor: "#00AEE9",
              border: "black",
              marginTop: "0.8em",
            }}
            type="primary"
          >
            Get a Callback
          </Button>
        </a>,
        <a href="https://medcaresolutions.co.za/medcare-admin/" className="mobile-display">
          <Button
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              marginLeft: "0.3em",
              width: "3em",
              fontSize: "17px",
              backgroundColor: "#00AEE9",
              border: "black",
              marginTop: "0.8em",
            }}
            type="primary"
          >
            <UserOutlined />
          </Button>
        </a>
      ]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className="Homepage">
    {window.isPhone ? (
      <div
        style={{
          width: "100vw",
          height: "8em",
          backgroundColor: "white",
          position: "fixed",
          zIndex: 9,
          paddingTop: "1em"
        }}
      >
        <img style={{ width: window.isPhone ? "13em" : "15em", position: "fixed" }} src={HeaderLogo} />
        <Example />
      </div>
    ) : (
      <MainHeader />
    )}
  </header>
);

export default Header;
