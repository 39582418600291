import React from "react";
import styled from "styled-components";
import { SettingFilled } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Menu,
  Select,
  Row,
  Col,
  Spin,
  Input,
  List,
  Switch,
  Badge,
  Button,
} from "antd";
import { Layout } from "antd";
import Resizer from "react-image-file-resizer";
import { ButtonSquare } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const FormItem = Form.Item;
const Option = Select.Option;
const Account = props => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <p>
            {" "}
            <StyledBadge
              color={
                props.props.user.paymentStatus === "Allow" ? "green" : "red"
              }
              style={{}}
            />
            Your account payment is{" "}
            <strong>
              {props.props.user.paymentStatus
                ? props.props.user.paymentStatus
                : "Not Allowed"}
            </strong>
          </p>
        </Col>
        <Col span={24} style={{ textAlign: "left", marginTop: "2em" }}>
          <a
            href="https://pay.yoco.com/south-african-figure-skating-association-gauteng-north"
            target="_blank"
          >
            <Button
              // onClick={e => this.setState({ yocoVisible: true })}
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "3em",
                marginRight: 10,
                marginLeft: 0,
              }}
            >
              Pay Now via Yoco
            </Button>
          </a>
          {/* <span style={{ paddingTop: '0.4em' }}>
            {'  '} or {'  '}
          </span>

          <Button
            onClick={e => this.setState({ eftVisible: true })}
            style={{
              backgroundColor: 'transparent',
              color: '#53B6F8',
              borderRadius: '3em',
              border: '0px solid #53B6F8',

              paddingRight: 6
            }}
          >
            EFT via listed Banking Details
          </Button> */}
        </Col>
        <Col
          span={24}
          style={{ textAlign: "left", marginTop: "2em", fontSize: "15px" }}
        >
          <p>
            <strong>EFT: </strong>
            <br />
            <strong>Account name: </strong>Account name: medcare-solutions
            <br />
            <strong>Bank: </strong> FNB
            <br />
            <strong>Universal branch Code: </strong> 250655
            <br />
            <strong>Account Number: </strong>6290 3190 122
            <br />
            <strong>Use as REFERENCE: </strong>Name, Surname & Event Name
          </p>
          <br />
          <p>
            Proof of payment can be mailed to{" "}
            <a href="mailto:chair@gnfigureskating.co.za">
              chair@gnfigureskating.co.za
            </a>{" "}
            <br />
            <strong>Use as REFERENCE:</strong> Name, Surname, & Event Name
          </p>
          <br />
          <p>
            For Information on your payment please contact us via email on any
            of the below liste email addresses:
          </p>
        </Col>
      </Row>
      {window.isPhone ? (
        <Row style={{ maxWidth: "55em" }}>
          <Col span={24} style={{}}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                marginTop: "1em",
                lineHeight: 1.4,
              }}
            >
              Chairperson:
              <br />
              <a style={{}} href="mailto:chair@gnfigureskating.co.za">
                chair@gnfigureskating.co.za
              </a>
              <br />
              Deputy Chairperson:
              <br />
              <a style={{}} href="mailto:deputy@gnfigureskating.co.za">
                deputy@gnfigureskating.co.za
              </a>
              <br />
              Secretary:
              <br />
              <a style={{}} href="mailto:secretary@gnfigureskating.co.za">
                secretary@gnfigureskating.co.za
              </a>
              <br />
              Treasurer:
              <a style={{}} href="mailto:finance@gnfigureskating.co.za">
                finance@gnfigureskating.co.za
              </a>
              <br />
              Member Secretary:
              <br />
              <a style={{}} href="mailto:chair@gnfigureskating.co.za">
                info@gnfigureskating.co.za
              </a>
              <br />
            </p>
          </Col>
          {/* <Col span={8} style={{}}>
         <p
           style={{
             fontSize: '16px',
             fontWeight: 600,
             marginTop: '1em',
             lineHeight: 1.4
           }}
         >
           Anne Waldek-Thill
           <br />
           Hayley Walker
           <br />
           Hayley Walker
           <br />
           Debbie Boshoff
           <br />
           Hayley Walker
           <br />
           Lorette Venter
           <br />
           Annalise du Plessis
           <br />
         </p>
       </Col> */}
          {/* <Col span={8} style={{}}>
         <p
           style={{
             fontSize: '16px',
             fontWeight: 600,
             marginTop: '1em',
             lineHeight: 1.4
           }}
         >
           <a style={{}} href="mailto:chair@gnfigureskating.co.za">
             chair@gnfigureskating.co.za
           </a>
           <br />
           <a style={{}} href="mailto:deputy@gnfigureskating.co.za">
             deputy@gnfigureskating.co.za
           </a>
           <br />
           <a style={{}} href="mailto:secretary@gnfigureskating.co.za">
             secretary@gnfigureskating.co.za
           </a>
           <br />
           <a style={{}} href="mailto:finance@gnfigureskating.co.za">
             finance@gnfigureskating.co.za
           </a>
           <br />
           <a style={{}} href="mailto:chair@gnfigureskating.co.za">
             info@gnfigureskating.co.za
           </a>
         </p>
       </Col> */}
        </Row>
      ) : (
        <Row style={{ maxWidth: "55em" }}>
          <Col span={8} style={{}}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                marginTop: "1em",
                lineHeight: 1.4,
              }}
            >
              Chairperson:
              <br />
              Deputy Chairperson:
              <br />
              Secretary:
              <br />
              Treasurer:
              <br />
              Member Secretary:
            </p>
          </Col>
          <Col span={8} style={{}}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                marginTop: "1em",
                lineHeight: 1.4,
              }}
            >
              Anne Waldek-Thill
              <br />
              Hayley Walker
              <br />
              Hayley Walker
              <br />
              Debbie Boshoff
              <br />
              Hayley Walker
              <br />
              Lorette Venter
              <br />
              Annalise du Plessis
              <br />
            </p>
          </Col>
          <Col span={8} style={{}}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                marginTop: "1em",
                lineHeight: 1.4,
              }}
            >
              <a style={{}} href="mailto:chair@gnfigureskating.co.za">
                chair@gnfigureskating.co.za
              </a>
              <br />
              <a style={{}} href="mailto:deputy@gnfigureskating.co.za">
                deputy@gnfigureskating.co.za
              </a>
              <br />
              <a style={{}} href="mailto:secretary@gnfigureskating.co.za">
                secretary@gnfigureskating.co.za
              </a>
              <br />
              <a style={{}} href="mailto:finance@gnfigureskating.co.za">
                finance@gnfigureskating.co.za
              </a>
              <br />
              <a style={{}} href="mailto:chair@gnfigureskating.co.za">
                info@gnfigureskating.co.za
              </a>
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("newPass", {
              rules: [
                { required: true, message: "New password required" },
                { validator: props.validateToNextPassword },
              ],
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("confirmPass", {
              rules: [
                { required: true, message: "Confirm new password" },
                { validator: props.compareToFirstPassword },
              ],
            })(
              <Input.Password
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when my car gets a bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when medcare-solutions reviews my stock
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me of medcare-solutions updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: this.props.user.image,
      activeView: "account",
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPass"], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              "success",
              "Success",
              "Successfully saved account information"
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify("success", "Success", "Successfully changed password");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined,
    });
  };
  render() {
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 20, marginTop: "2em" }}>
            <span style={{ fontWeight: 750, fontSize: 25 }}>Account</span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Layout>
                <Layout>
                  <Layout>
                    <Content
                      style={{
                        background: "white",
                        padding: window.isPhone ? 0 : 40,
                        paddingTop: window.isPhone ? 10 : 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Account
                        props={this.props}
                        image={this.state.image}
                        beforeUpload={this.beforeUpload}
                        handleImageUpload={this.handleImageUpload}
                        handleProfileImage={this.handleProfileImage}
                        handleAccountInfoSave={this.handleAccountInfoSave}
                        removeImage={this.removeImage}
                      />
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
