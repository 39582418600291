import React from "react";
import styled from "styled-components";
import { SettingFilled } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Menu, Select, Row, Col, Spin, Input, List, Switch } from "antd";
import { Layout } from "antd";
import Resizer from "react-image-file-resizer";
import { ButtonSquare } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const FormItem = Form.Item;
const Option = Select.Option;
const Account = props => {
  return (
    <Form layout="vertical">
      <Row>
        <Col span={10}>
          <FormItem label={<span>Full Name</span>}>
            {props.props.form.getFieldDecorator("name", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  //pattern: RegExp(
                  //  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                  //),
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user.name,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Number</span>}>
            {props.props.form.getFieldDecorator("number", {
              rules: [
                { required: true, message: "User number required" },
                {
                  pattern: RegExp(/\d/g),
                  message: "A valid number is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.number : "",
            })(<Input maxLength={10} />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Email</span>}>
            {props.props.form.getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Email address is required.",
                },
                {
                  type: "email",
                  message: "A valid email is required.",
                },
              ],
              initialValue: props.props.user ? props.props.user.email : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Country of Birth</span>}>
            {props.props.form.getFieldDecorator("countryOfBirth", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user
                ? props.props.user.countryOfBirth
                : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>ID/Passport Number</span>}>
            {props.props.form.getFieldDecorator("identityNumber", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user
                ? props.props.user.identityNumber
                : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Address</span>}>
            {props.props.form.getFieldDecorator("address", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.address : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Race</span>}>
            {props.props.form.getFieldDecorator("race", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.race : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Gender</span>}>
            {props.props.form.getFieldDecorator("gender", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.gender : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Club</span>}>
            {props.props.form.getFieldDecorator("club", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.club : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Skating School</span>}>
            {props.props.form.getFieldDecorator("school", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.school : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Head Coach</span>}>
            {props.props.form.getFieldDecorator("coach", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user ? props.props.user.coach : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Membership Number</span>}>
            {props.props.form.getFieldDecorator("memberNumber", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user
                ? props.props.user.memberNumber
                : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Level Passed</span>}>
            {props.props.form.getFieldDecorator("levelPassed", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user
                ? props.props.user.levelPassed
                : "",
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={2} />
        <Col span={10}>
          <FormItem label={<span>Competition</span>}>
            {props.props.form.getFieldDecorator("competition", {
              rules: [
                { required: true, message: "Field entry required" },
                {
                  message: "A name and surname is required",
                },
              ],
              initialValue: props.props.user
                ? props.props.user.competition
                : "",
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
      {/*<FormItem label={<span>Brand</span>}>
      {props.props.form.getFieldDecorator("brand", {
        rules: [{ required: false, message: "Brand is required" }],
        initialValue: props.props.user ? props.props.user.brand : "",
      })(
        <Select>
          <Option value='megatheron'>Mega Theron</Option>
          <Option value='kia'>Kia</Option>
          <Option value='mahindra'>Mahindra</Option>
          <Option value='chery'>Chery</Option>
          <Option value='gwm'>GWM</Option>
          <Option value='haval'>Haval</Option>
        </Select>
      )}
    </FormItem>
    <FormItem label={<span>Branch</span>}>
      {props.props.form.getFieldDecorator("branch", {
        rules: [
          {
            required: false,
            message: "Branch is required",
          },
        ],
        initialValue: props.props.user ? props.props.user.branch : "",
      })(
        <Select>
          <Option value='klerksdorp'>Klerksdorp</Option>
          <Option value='potchefstroom'>Potchefstroom</Option>
          <Option value='mahikeng'>Mahikeng</Option>
          <Option value='lichtenburg'>Lichtenburg</Option>
        </Select>
      )}
    </FormItem>*/}
      {/*<FormItem label={"Roles Stock"}>
      {props.props.form.getFieldDecorator("isAdd", {
        valuePropName: "checked",
        initialValue: props.props.user
          ? props.props.user.isAdd
            ? true
            : false
          : false,
      })(<Checkbox>Add Stock</Checkbox>)}
      {props.props.form.getFieldDecorator("isEdit", {
        valuePropName: "checked",
        initialValue: props.props.user
          ? props.props.user.isEdit
            ? true
            : false
          : false,
      })(<Checkbox>Edit Stock</Checkbox>)}
      {props.props.form.getFieldDecorator("isDelete", {
        valuePropName: "checked",
        initialValue: props.props.user
          ? props.props.user.isDelete
            ? true
            : false
          : false,
      })(<Checkbox>Delete Stock</Checkbox>)}*/}
      {/*{props.props.form.getFieldDecorator('isBatch', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isBatch
            ? true
            : false
          : false
      })(<Checkbox>Batch Request</Checkbox>)}*/}
      {/*</FormItem>*/}
      {/*<FormItem>
      {props.props.form.getFieldDecorator('isStockOut', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isStockOut
            ? true
            : false
          : false
      })(<Checkbox>Submit Stock Out</Checkbox>)}
    </FormItem>*/}
      {/*<FormItem label={'Roles PEX'}>
      {props.props.form.getFieldDecorator('isPexAdd', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isPexAdd
            ? true
            : false
          : false
      })(<Checkbox>Add Stock</Checkbox>)}
      {props.props.form.getFieldDecorator('isPexEdit', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isPexEdit
            ? true
            : false
          : false
      })(<Checkbox>Edit Stock</Checkbox>)}
      {props.props.form.getFieldDecorator('isPexDelete', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isPexDelete
            ? true
            : false
          : false
      })(<Checkbox>Delete Stock</Checkbox>)}
    </FormItem>
    <FormItem>
      {props.props.form.getFieldDecorator('isPexOut', {
        valuePropName: 'checked',
        initialValue: props.props.user
          ? props.props.user.isPexOut
            ? true
            : false
          : false
      })(<Checkbox>PEX Unit Out</Checkbox>)}
    </FormItem>*/}
    </Form>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("newPass", {
              rules: [
                { required: true, message: "New password required" },
                { validator: props.validateToNextPassword },
              ],
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator("confirmPass", {
              rules: [
                { required: true, message: "Confirm new password" },
                { validator: props.compareToFirstPassword },
              ],
            })(
              <Input.Password
                type="password"
                style={{ borderColor: "#BDB2B2", height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when my car gets a bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me when medcare-solutions reviews my stock
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: "#6D6E70", fontSize: 16 }}>
                Notify me of medcare-solutions updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: this.props.user.image,
      activeView: "account",
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPass"], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              "success",
              "Success",
              "Successfully saved account information"
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify("success", "Success", "Successfully changed password");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", window.i18n.translate("Notify3"), e);
          });
      }
    });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl,
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined,
    });
  };
  render() {
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 0, marginTop: 20 }}>
            <span style={{ fontWeight: 750, fontSize: 25 }}>
              Member Profile
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Layout>
                <Layout>
                  <Layout>
                    <Content
                      style={{
                        background: "white",
                        padding: 40,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Account
                        props={this.props}
                        image={this.state.image}
                        beforeUpload={this.beforeUpload}
                        handleImageUpload={this.handleImageUpload}
                        handleProfileImage={this.handleProfileImage}
                        handleAccountInfoSave={this.handleAccountInfoSave}
                        removeImage={this.removeImage}
                      />
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
