import React from "react";
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Link } from "react-router-dom";
import Logo from "src/static/logo2.svg";
import "@ant-design/compatible/assets/index.css";
import MainLogo from "../../../static/Home/logo-white.svg";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox,
  Badge,
  InputNumber,
} from "antd";
import Fade from "react-reveal/Fade";
import { ButtonRadius } from "src/components/elements/Button";
import Highlighter from "react-highlight-words";
import Notify from "src/components/meta/Notification";
import styled from "styled-components";
import { regExpLiteral } from "@babel/types";
import HomeImage from "src/static/Home/home-banner-desktop.png";
import About1 from "src/static/Home/about-1.png";
import AboutImage from "src/static/Home/about-background.svg";
import AboutImageMobile from "src/static/Home/about-background-mobile.svg";
import AboutBackImage from "src/static/Home/about-back.png";
import HomeImageMobile from "src/static/Home/home-banner-mobile.png";
import QuoteImage from "src/static/Home/quote-image.svg";
import BestMedImage from "src/static/Home/best-med-img.png";
import FedhealthImage from "src/static/Home/fedhealth-img.png";
import MediHelpImage from "src/static/Home/medihelp-img.png";
import DiscoveryImage from "src/static/Home/discovery-img.png";
import WingLeft from "../../../static/Home/wing-left.png";
import WingRight from "../../../static/Home/wing-right.png";
import WhatsappImg from "src/static/Home/whatsapp-img.svg";
import DepImage from "src/static/Home/department-image.png";

import LandingBanner from "../../../static/Home/landing-banner-image.png";
import BonitasImage from "../../../static/Home/bonitas-logo.png";
import MomentumImage from "../../../static/Home/momentum-image.png";
import KingPriceImage from "../../../static/Home/KP-image.png";


import Header from "./Header";
import Footer from "./Footer";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import LogoModal from "src/static/Home/main-logo.svg";
import NoticeModal from "src/static/Home/pricing-notice.png";
import { RightOutlined, ArrowRightOutlined } from "@ant-design/icons";
import $ from "jquery";
import { CgArrowLongRight } from "react-icons/cg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PayfastLink = "https://www.payfast.co.za/eng/process";
const MerchantID = "16762989";
const MerchantKey = "cfbfm504ddvqp";
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const numeral = require("numeral");
const { TabPane } = Tabs;

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsCeleb = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsPrograms = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0px !important;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 60px !important;
  padding: 14px 0px;
  background-color: white !important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 600px) {
    width: 76vw !important;
  margin:auto;
    }
  }
`;
const StyledSelect = styled(Select)`

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  text-align:left !important;
  border: 0px solid lightgrey;
  border-radius: 0.2em !important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  height: 60px !important;



  :hover {
    border: 0px solid lightgrey important;
    height: 60px !important;
    border-radius: 0.5em !important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  }
  :active {
    border: 0px solid lightgrey important;
    height: 60px !important;
    border-radius: 0.5em !important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  }
  :focus {
    border: 0px solid lightgrey important;
    height: 60px !important;
    border-radius: 0.5em !important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 60px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;
 
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black!important;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-bottom: 0px solid red !important;
  }
  :active{
    border-bottom: 0px red !important;
  }
  @media only screen and (max-width: 600px) {
    width: 76vw;
  margin:auto;
    }
  }
`;

const StyledInput1 = styled(Input)`
-webkit-box-sizing: border-box;
box-sizing: border-box;
padding-left: 1.2em !important;
font-variant: tabular-nums;
list-style: none;
-webkit-font-feature-settings: "tnum", "tnum";
font-feature-settings: "tnum", "tnum";
position: relative;
height: 60px !important;
padding: 4px 0px ;
background-color: white!important;
background-image: none;
border: none;
border-bottom: 0px solid white;

-webkit-transition: all 0.3s;
transition: all 0.3s;
color: black!important;
box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-bottom: 0px solid red !important;
}
:active{
  border-bottom: 0px red !important;
}
@media only screen and (max-width: 600px) {
  width: 76vw;
margin:auto;
  }
}
`;
const { Meta } = Card;
class AddContactForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Form layout="vertical">
        <Row>
          <Col xs={0} md={1} />
          <Col span={5} style={{ display: "none" }}>
            <FormItem hasFeedback label={<span>Date</span>}>
              {this.props.form.getFieldDecorator("timeDate", {
                rules: [{}],
                initialValue: this.state.curDT,
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  value={this.state.curDT}
                  defaultValue={this.state.curDT}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={0} md={1} />
        </Row>
        <Row>
          <Col span={24} style={{ display: "none" }}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("condition", {
                rules: [
                  {
                    required: true,
                    message: "Email Address required",
                  },
                ],
                initialValue: "CALLBACK LEAD",
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  placeholder="Email Address"
                />
              )}
            </FormItem>
          </Col>
          <Col
            xs={24}
            md={7}
            style={{ marginLeft: window.isPhone ? "" : "1em" }}
          >
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("eventTitle", {
                rules: [
                  {
                    required: true,
                    message: "Name & Surname required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.eventTitle
                  : "",
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  placeholder="Name & Surname"
                />
              )}
            </FormItem>
          </Col>
          <Col xs={0} md={1} />
          <Col xs={24} md={7}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("contactNumber", {
                rules: [
                  {
                    max: 10,
                    min: 10,

                    required: true,
                    message: "Number required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.contactNumber
                  : "",
              })(
                <StyledInput
                  type="number"
                  style={{ width: window.isPhone ? "90%" : "" }}
                  placeholder="Contact Number"
                />
              )}
            </FormItem>
          </Col>
          <Col xs={0} md={1} />
          <Col xs={24} md={7}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("leadEmail", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Email Address required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.leadEmail
                  : "",
              })(
                <StyledInput
                  type="email"
                  style={{ width: window.isPhone ? "90%" : "" }}
                  placeholder="Email Address"
                />
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-between" align="middle" />
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>{" "}
      </Form>
    );
  }
}
const ContactForm = Form.create()(AddContactForm);
class AddStockForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form layout="vertical">
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "QUOTE REQUEST",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>

              <Col
                xs={24}
                md={8}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Name & Surname required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(<StyledInput size="large" placeholder="Name & Surname" />)}
                </FormItem>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        required: true,
                        message: "Contact Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput
                      type="number"
                      size="large"
                      placeholder="Contact Number"
                    />
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        message: "Email required",
                      },
                    ],

                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput
                      type="email"
                      size="large"
                      placeholder="Email"
                    />
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                md={6}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("adultTotal", {
                    rules: [
                      {
                        required: false,
                        message: "Email required",
                      },
                    ],

                    initialValue: this.props.editStock
                      ? this.props.editStock.adultTotal
                      : "",
                  })(<StyledInput size="large" placeholder="Nr. Adults" />)}
                </FormItem>
              </Col>
              <Col
                xs={24}
                md={6}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("childTotal", {
                    rules: [
                      {
                        required: false,
                        message: "Email required",
                      },
                    ],

                    initialValue: this.props.editStock
                      ? this.props.editStock.childTotal
                      : "",
                  })(<StyledInput size="large" placeholder="Nr. Children" />)}
                </FormItem>
              </Col>

              <Col
                xs={24}
                md={12}
                style={{ padding: window.isPhone ? "" : "1em" }}
              >
                <FormItem>
                  {this.props.form.getFieldDecorator("budget", {
                    rules: [
                      {
                        required: false,
                        message: "Email required",
                      },
                    ],

                    initialValue: this.props.editStock
                      ? this.props.editStock.budget
                      : "",
                  })(
                    <StyledInputNumber
                      placeholder="Approximate Monthly Budget"
                      style={{ width: "100%" }}
                      formatter={value =>
                        `R ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={value => value.replace(/\R\s?|(,*)/g, "")}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={24} style={{ padding: window.isPhone ? "" : "1em" }}>
                <FormItem>
                  {this.props.form.getFieldDecorator("address", {
                    rules: [
                      {
                        required: false,
                        message: "Email required",
                      },
                    ],

                    initialValue: this.props.editStock
                      ? this.props.editStock.address
                      : "",
                  })(<StyledSelect size="large" placeholder="Address">
                    <Option
                      key={"MEDICAL"}
                      value={"MEDICAL"}
                    >
                      MEDICAL
                    </Option>
                    <Option
                      key={"SHORT-TERM"}
                      value={"SHORT-TERM"}
                    >
                      SHORT-TERM
                    </Option>
                  </StyledSelect>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const StockForm = Form.create()(AddStockForm);
class AddStockWeekForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form layout="vertical">
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "12 WEEK PLAN",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Name & Surname required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Name & Surname"
                      value={this.state.inputValueName}
                      onChange={evt => this.updateInputValueName(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={2} />
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 10,
                        min: 10,

                        required: true,
                        message: "Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput1
                      type="number"
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Contact Number"
                      value={this.state.inputValueNumber}
                      onChange={evt => this.updateInputValueNumber(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Email Address required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Email Address"
                      value={this.state.inputValueEmail}
                      onChange={evt => this.updateInputValueEmail(evt)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row type="flex" justify="space-between" align="middle" />
            {/* <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>{' '} */}
          </Form>
        </Col>
      </Row>
    );
  }
}

const StockWeekForm = Form.create()(AddStockWeekForm);
class AddStockPremiumForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form layout="vertical">
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "PREMIUM PLAN",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Name & Surname required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Name & Surname"
                      value={this.state.inputValueName}
                      onChange={evt => this.updateInputValueName(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={2} />
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 10,
                        min: 10,

                        required: true,
                        message: "Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput1
                      type="number"
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Contact Number"
                      value={this.state.inputValueNumber}
                      onChange={evt => this.updateInputValueNumber(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Email Address required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Email Address"
                      value={this.state.inputValueEmail}
                      onChange={evt => this.updateInputValueEmail(evt)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row type="flex" justify="space-between" align="middle" />
            {/* <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>{' '} */}
          </Form>
        </Col>
      </Row>
    );
  }
}

const StockFormPremium = Form.create()(AddStockPremiumForm);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editDealer: undefined,
      notifModal: true,
      editManager: undefined,
      editUser: undefined,
      successModal: false,
      buyYearModal: false,
      signUpButtonVisible: true,
      checkoutVisible: true,
      viewMore: false,
      buyWeekModal: false,
      buyPremiumModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };
  handleInputChangeName(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/userdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === "ascend") {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  updateInputValue(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValue: val,
    });
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),

    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "black" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  StockForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.vehiclesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            Notify(
              "success",
              "Successfully Sent, thank you we'll be in touch shortly"
            );
            this.vehiclesForm.props.form.resetFields();
            this.setState({
              loading: false,
              signUpButtonVisible: false,
              previewVisible: false,
              checkoutVisible: false,
              signUpVisible: true,
              editStock: undefined,
              currentTab: "All",
            });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Medcare Solutions";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };
  handleNotifModal = () => {
    this.setState({ successModal: false, notifModal: false, });
    // window.location.assign("./");
  };
  handleOpenNotif = () => {
    this.setState({ notifModal: true, notifVisible: true });
  };
  handleOpenYearModal = () => {
    this.setState({ buyYearModal: true, signUpVisible: true });
  };
  handleCloseYearModal = () => {
    this.setState({ buyYearModal: false });
  };
  handleOpenWeekModal = () => {
    this.setState({ buyWeekModal: true, signUpVisible: true });
  };
  handleCloseWeekModal = () => {
    this.setState({ buyWeekModal: false });
  };
  handleOpenPremiumModal = () => {
    this.setState({ buyPremiumModal: true, signUpVisible: true });
  };
  handleClosePremiumModal = () => {
    this.setState({ buyPremiumModal: false });
  };
  onFinish = values => {
    const MSG = {
      to: ["ianels182@gmail.com"],
      from: "info@medcare-solutions.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `

      <style type="text/css">
        body, #bodyTable{background - color:white}
        #emailHeader{background - color:white}
        #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background - color:#E1E1E1;}
      </style>
      <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
        <center style="background-color:#E1E1E1;">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                  <!-- HEADER ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->
                            <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>

                                      <td align="right" valign="middle" class="flexibleContainerBox">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                          <tr>
                                            <td align="left" class="textContent">
                                              <!-- CONTENT // -->

                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <!-- // FLEXIBLE CONTAINER -->
                          </td>
                        </tr>
                      </table>
                      <!-- // CENTERING TABLE -->
                    </td>
                  </tr>
                  <!-- // END -->
                </table>
                <!-- EMAIL BODY // -->
                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
                  <!-- MODULE ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="#101010">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->

                            <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->

                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell  ">
                                      <img alt="" src="https://i.ibb.co/5BmY3qC/main-logo-4ec06f084b29ddd52e07b0ef1b81f7d6-1.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                        <td align="center" valign="top" class="textContent">
                                          <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                          <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br />medcare-solutions website</h2>
                                        </td>
                                      </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->
                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          <!-- // CENTERING TABLE -->
                        </td>
                      </tr>
                      <!-- MODULE ROW // -->
                      <tr mc:hideable>
                        <td align="center" valign="top">
                          <!-- CENTERING TABLE // -->
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                            <tr>
                              <td align="center" valign="top">
                                <!-- FLEXIBLE CONTAINER // -->
                                <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                                  <tr>
                                    <td valign="top" width="500" class="flexibleContainerCell">
                                      <!-- CONTENT TABLE // -->
                                      <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                                        <tr>
                                          <td align="center" valign="top" class="flexibleContainerBox">
                                            <table border="0" cellspacing="0" cellpadding="0" >

                                              <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                                                <td class="role"><br /><br /><br /><br /><br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name
        }<br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.surname
        }<br />
                                                  <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email
        }<br />
                                                  <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.message
        }
                                                  <br /><br /><br />
                                                </td>
                                              </tr>
                                              <table>
                                              </td>
                                            </tr>
                                          </table>
                                          <!-- // CONTENT TABLE -->

                                        </td>
                                      </tr>
                                    </table>
                                    <!-- // FLEXIBLE CONTAINER -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // CENTERING TABLE -->
                            </td>
                          </tr>
                          <!-- // MODULE ROW -->


                          <!-- MODULE ROW // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                            <tr>
                              <td align="center" valign="top">

                                <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                                  <tr>
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell">


                                      <table border="0" cellpadding="30" cellspacing="0" width="100%">


                                        <tr>
                                          <td align="center" valign="top" class="textContent">

                                            <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@medcare-solutions</h2>

                                          </td>
                                        </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->

                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        surname: "",
        message: "",
      });
    });
  };
  render() {
    const { user } = this.props;
    return window.isPhone ? (
      <div>
        <Spin spinning={this.state.loading}>
          <Header />
          <Row
            style={{ maxWidth: "90em", margin: "auto", textAlign: "center" }}
          >
            <Col sm={24} md={13} className="home-col">
              <Fade bottom>
                <h1
                  style={{
                    marginTop: "4em",
                    fontWeight: 700,
                    fontSize: 50,
                    color: "#00AEE9",
                  }}
                >
                  Medical Care
                </h1>{" "}
                <h1
                  style={{
                    fontWeight: 500,
                    letterSpacing: 5,
                    lineHeight: "1em",
                    fontSize: 35,
                    marginTop: "-1em",
                    color: "#3D3D3D",
                  }}
                >
                  Simplified For Everyone
                </h1>{" "}
                <p
                  className="mulish-paragraph-small"
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    marginTop: "0.5rem",
                    marginBottom: "1rem",
                    lineHeight: "1.3em",
                    color: "#3D3D3D",
                    padding: "1em",
                  }}
                >
                  Good health is the state of mental, physical and social well being and it does not just mean absence of diseases.
                </p>{" "}
                <Fade bottom>
                  <div className="home-button">
                    <span
                      onClick={this.handleOpenYearModal}
                      style={{ cursor: "pointer" }}
                    >
                      <img style={{ width: "5.5em" }} src={QuoteImage} />
                      <span
                        style={{
                          borderRadius: 10,
                          fontWeight: 500,
                          height: "4em",
                          color: "#00AEE9",
                          fontSize: "17px",
                          backgroundColor: "transparent",
                          border: "black",
                          marginTop: "3em",
                          marginLeft: "0.8em",
                          boxShadow: "none",
                        }}
                        type="primary"
                      >
                        Get a Quote
                      </span>
                    </span>
                    <br />
                    <a href="#book">
                      <Button
                        className="btn-grad"
                        style={{
                          borderRadius: 10,
                          fontWeight: 500,
                          height: "4em",
                          width: "15em",
                          fontSize: "17px",
                          backgroundColor: "#00AEE9",
                          border: "black",
                          marginTop: "1.5em",
                        }}
                        type="primary"
                      >
                        Book an appointment
                      </Button>
                    </a>
                  </div>
                </Fade>
              </Fade>
            </Col>
            <Col sm={24} md={11}
              style={{
                margin: "auto",
                marginTop: "4em",
              }}
            >
              <img
                // width={330}
                // height={240}
                src={LandingBanner}
                alt="medcare-solutions-Banner"
                style={{
                  width: "90%",
                  height: "90%"
                }}
              />
            </Col>
          </Row>
          <Row style={{ width: "100vw" }} />
          <Row
            id="about-us"
            style={{
              maxWidth: "100vw",
              textAlign: "center",
              margin: "auto",
              marginTop: "5em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <Col xs={24} md={12}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "-0em",
                    // color: "#004242",
                  }}
                >
                  Why Join Medcare Solutions?
                </p>
                <p
                  className="Mulish-center"
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "32px",
                    marginBottom: "-0em",
                    lineHeight: 1.3,
                    paddingRight: window.isPhone ? "0em" : "3em",
                  }}
                >
                  Medcare has the infrastructure to offer all our clients
                  professional & quality services.
                </p>
              </Fade>
            </Col>
            <Col xs={24} md={12}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontSize: 18,
                    fontWeight: 400,
                    marginTop: "1.5rem",
                    color: "black",
                    paddingRight: window.isPhone ? "1em" : "",
                    paddingLeft: window.isPhone ? "1em" : "",
                  }}
                >
                  More cover for everyone they love, more care for every time
                  they need us, more simplicity in a world of chaos and
                  confusion, and more value for every rand they spend.
                </p>{" "}
                <div
                  onClick={this.handleOpenYearModal}
                  style={{
                    cursor: "pointer",
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "-0em",
                    color: "rgb(0, 174, 233)",
                  }}
                >
                  <img
                    style={{
                      width: "2em",
                      marginLeft: "-0.4em",
                      marginRight: "0.3em",
                    }}
                    src={QuoteImage}
                  />
                  Get a quote Today
                </div>
              </Fade>
            </Col>
          </Row>
          <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "5em" }}>
            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={<p className="color-change">Exclusive member</p>}
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          We ensure you get more for less. In addition to
                          receiving affordable, quality healthcare, being part
                          of the Medcare Solutions means you get to benefit from
                          offers that make your money go further.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>

            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={<p className="color-change">Client-focused</p>}
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          Our full suite of integrated services fulfills the
                          needs of major medical schemes, health plans and a
                          growing list healthcare clients. Medcare Solutions
                          end-to-end client-focused service offering provides
                          sound value within the highly complex and dynamic
                          healthcare environment.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>

            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={<p className="color-change">Medcare Solutions</p>}
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          As a fully accredited, full-service third party
                          administrator and managed care organisation with a
                          proven track record, we are established in the
                          provision of evidence-based, integrated healthcare
                          solutions aligned to national best practice.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: window.isPhone ? "1em" : "5em",
              maxWidth: "90em",
              padding: "1em",
              textAlign: window.isPhone ? "center" : "",
            }}
          >
            <img
              style={{
                width: "100vw",
                position: "absolute",
                display: "none",
                top: "108em",
                left: 0,
                height: "60em",
                objectFit: "cover",
              }}
              src={AboutBackImage}
              alt="medcare-solutions-Banner"
            />
            <Col xs={0} md={1} />
            <Col xs={24} md={10} style={{ margin: "auto" }}>
              <Fade>
                <h1 style={{ marginTop: "1em", color: "black" }}>
                  The Medcare Promise
                  <br />
                </h1>
                <h1
                  style={{
                    fontSize: "40px",
                    color: "black",
                    lineHeight: "1em",
                  }}
                >
                  Your health is our number one highest priority
                  <br />
                </h1>
                <p
                  style={{
                    paddingRight: window.isPhone ? "0em" : "6em",
                    marginTop: "1.5em",
                    marginBottom: "1.5em",
                    fontSize: "16px",
                  }}
                >
                  Everyone deserve a better treatment for their health care,
                  including you and that is our main priority
                </p>
                <a href="#book">
                  <Button
                    // onClick={this.handleOpenYearModal}
                    className="btn-grad"
                    style={{
                      border: "1px solid #27aee7",
                      borderRadius: "3em",
                      fontSize: "15px",
                      fontWeight: 600,
                      backgroundColor: "#0E82B6",
                      color: "white",
                      height: "45px",
                      paddingTop: "0.2em",
                      paddingLeft: "2em",
                      paddingRight: "2em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "1em",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Get a Callback
                  </Button>
                </a>
              </Fade>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{ textAlign: "center", marginTop: "0em" }}
            >
              <img
                style={{ width: "100vw", marginLeft: "-1.2em" }}
                src={AboutImageMobile}
                alt="medcare-solutions-Banner"
              />
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: window.isPhone ? "1em" : "5em",
              maxWidth: "90em",
              padding: "1em",
              textAlign: window.isPhone ? "center" : "",
            }}
          >
            <Col xs={24} md={10} style={{ margin: "auto" }}>
              <Fade>
                <div
                  style={{
                    borderTop: "7px solid #00AEE9",
                    width: "7em",
                    margin: "auto"
                  }}
                />
                <h1 style={{ marginTop: "1em", color: "black" }}>
                  Superior Service
                  <br />
                </h1>
                <h1
                  style={{
                    fontSize: "40px",
                    color: "black",
                    lineHeight: "1em",
                  }}
                >
                  We offer Superior Quality at a Great Price
                  <br />
                </h1>
                <p
                  style={{
                    marginTop: "1.5em",
                    fontSize: "16px",
                    marginBottom: "1.5em",
                  }}
                >
                  Each of our options offers affordably priced benefits. We
                  continuously review and improve the range of benefits, in each
                  option, to bring you what you really need – quality healthcare
                  at affordable prices and a range of plans that suit your
                  specific, individual needs.
                </p>
                <Button
                  onClick={this.handleOpenYearModal}
                  className="btn-grad"
                  style={{
                    border: "1px solid #27aee7",
                    borderRadius: "3em",
                    fontSize: "15px",
                    fontWeight: 600,

                    backgroundColor: "#0E82B6",
                    color: "white",
                    height: "45px",
                    paddingTop: "0.2em",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    marginTop: "0em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Let's Talk
                </Button>{" "}
              </Fade>
            </Col>
            <Col xs={24} md={10} style={{ textAlign: "center" }}>
              <Fade>
                <img
                  style={{ width: "20em", marginTop: "2em" }}
                  src={DepImage}
                  alt="medcare-solutions-Banner"
                />
              </Fade>
            </Col>
          </Row>
          <Row
            id="medical-care"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              padding: "1em",
              textAlign: "center",
              marginTop: "2em",
              paddingBottom: "2em",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <h1 style={{ marginTop: "2em", color: "black" }}>
                Only the Best Medical Aid
              </h1>
              <h1
                style={{
                  margin: "auto",
                  marginTop: "0.5em",
                  lineHeight: "1em",
                  fontSize: "35px",
                  color: "black",
                }}
              >
                Our clients get quality cover from the best service providers
              </h1>
              <p
                style={{
                  fontSize: 18,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                We compare quotes from our range of suppliers
              </p>{" "}
              <p
                style={{
                  fontSize: 18,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                <span style={{ fontSize: "14px" }}>Starting price</span> <br />
                <span style={{ fontSize: "18px", fontWeight: 700 }}>
                  {" "}
                  Individual
                </span>{" "}
                - R1 252
                <br />
                <span style={{ fontSize: "18px", fontWeight: 700 }}>
                  {" "}
                  Family
                </span>{" "}
                - R3 066
              </p>
            </Col>
          </Row>
          <Row style={{ maxWidth: "90vw", margin: "auto", marginTop: "2em" }}>
            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "70%" : "70%",
                  // marginTop: "-1em",
                }}
                src={BonitasImage}
              />
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={MomentumImage}
              />
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "90%" : "70%",
                  marginTop: "1.9em",
                }}
                src={KingPriceImage}
              />
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "90%" : "70%",
                  marginTop: "1.4em",
                }}
                src={BestMedImage}
              />
            </Col>
          </Row>
          <Row style={{ maxWidth: "90vw", margin: "auto", marginTop: "0.5em" }}>

            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={FedhealthImage}
              />
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "100%" : "70%",
                  marginTop: "-2em",
                }}
                src={MediHelpImage}
              />
            </Col>
            <Col span={24} style={{
              textAlign: "center",
              marginTop: "0.5em",
            }}>
              <img
                style={{ width: window.isPhone ? "50%" : "70%" }}
                src={DiscoveryImage}
              />
            </Col>
          </Row>
          <Row
            id="about-us"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              marginTop: "3em",
            }}
          >
            <Col xs={24} md={18}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "-0em",
                    // color: "#004242",
                  }}
                >
                  We'll come to you!
                </p>
                <p
                  className="Mulish-center"
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "32px",
                    marginBottom: "-0em",
                    lineHeight: 1.3,
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                  }}
                >
                  We make it easy to schedule an appointment at any convenient
                  time & location.
                </p>
              </Fade>
            </Col>
            <Col xs={24} md={6} style={{ textAlign: "center" }}>
              <Fade>
                <Button
                  onClick={this.handleOpenYearModal}
                  className="btn-grad"
                  style={{
                    borderRadius: 10,
                    fontWeight: 500,
                    borderRadius: "3em",
                    height: "4em",
                    width: "15em",
                    fontSize: "17px",
                    backgroundColor: "#00AEE9",
                    border: "black",
                    margin: "auto",
                    marginTop: "3em",
                  }}
                  type="primary"
                >
                  Get your custom quote
                </Button>
              </Fade>
            </Col>
          </Row>
          <Row
            id="book"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              textAlign: "center",
              marginTop: "4.5em",
              paddingBottom: "2em",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <h1 style={{ marginTop: "1em", color: "black" }}>
                We'll Contact You
              </h1>
              <h1
                style={{
                  margin: "auto",
                  marginTop: "-0.5em",
                  fontSize: "35px",
                  color: "black",
                }}
              >
                Get a Callback
              </h1>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                Get Started with Medcare today!
              </p>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              maxWidth: "70em",
              textAlign: "center",
            }}
          >
            <img
              className="contact-image"
              style={{
                marginTop: window.isPhone ? "-8em" : "-15em",
                left: window.isPhone ? "-8em" : 0,
                zIndex: 0,
                position: "absolute",
              }}
              src={WingLeft}
            />
            <img
              className="hori-line"
              style={{
                marginTop: window.isPhone ? "-5em" : "-15em",
                right: 0,
                zIndex: 0,
                position: "absolute",
              }}
              src={WingRight}
            />{" "}
            <Col xs={24} md={24}>
              <ContactForm
                editStock={this.state.editStock}
                wrappedComponentRef={form => (this.vehiclesForm = form)}
              />
              <Button
                className="btn-grad"
                onClick={this.handleAddStock}
                style={{
                  borderRadius: "3em",
                  border: "1px solid #27aee7",
                  fontSize: "17px",
                  fontWeight: 600,

                  backgroundColor: "#0E82B6",
                  color: "white",
                  height: "55px",
                  paddingTop: "0.2em",
                  paddingLeft: "3em",
                  paddingRight: "3em",
                  textShadow: "none",
                  margin: "auto",
                  marginTop: "2em",
                }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>{" "}
            </Col>
          </Row>{" "}
          <SuccessModal
            open={this.state.buyYearModal}
            onClose={this.handleCloseYearModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "80em",
                    margin: "auto",
                    textAlign: "center",
                    // marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "white"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: this.state.signUpButtonVisible
                      ? "4em"
                      : "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div style={{ marginBottom: "3em" }}>
                          <img
                            className="header-logo"
                            style={{ width: "15em" }}
                            src={MainLogo}
                          />
                          <h1
                            style={{
                              marginTop: "2em",
                              fontSize: "15px",
                              color: "black",
                            }}
                          >
                            Only the Best Medical Aid
                          </h1>
                          <h1
                            style={{
                              margin: "auto",
                              marginTop: "-0.5em",
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            We'll get back to you with the best option available
                          </h1>
                        </div>
                      ) : (
                        <div style={{ marginBottom: "3em" }}>
                          <img
                            className="header-logo"
                            style={{ width: "15em" }}
                            src={MainLogo}
                          />
                          <h1
                            style={{
                              marginTop: "2em",
                              fontSize: "15px",
                              color: "black",
                            }}
                          >
                            Thank You for choosing Medcare Solutions
                          </h1>
                          <h1
                            style={{
                              margin: "auto",
                              marginTop: "-0.5em",
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            We'll be in touch shortly
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {this.state.signUpButtonVisible ? (
                    <StockForm
                      editStock={this.state.editStock}
                      wrappedComponentRef={form => (this.vehiclesForm = form)}
                    />
                  ) : null}
                  {this.state.signUpButtonVisible ? (
                    <ButtonRadius
                      onClick={this.handleAddStock}
                      className="btn-grad"
                      style={{
                        border: "1px solid #27aee7",
                        borderRadius: "3em",
                        fontSize: "15px",
                        fontWeight: 600,

                        backgroundColor: "#0E82B6",
                        color: "white",
                        width: "13em",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "2em",
                      }}
                      type="primary"
                      htmlType="submit"
                      block
                    >
                      Request Quote
                    </ButtonRadius>
                  ) : null}
                </div>
              </div>
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyWeekModal}
            onClose={this.handleCloseWeekModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> 12 WEEK PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockWeekForm
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyPremiumModal}
            onClose={this.handleClosePremiumModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> PREMIUM PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockFormPremium
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
            <h3
              style={{
                fontFamily: "Crimson Text",
                marginTop: "1em",
                color: "white",
                textAlign: "center",
              }}
            >
              Thank you for contacting
              <span>
                <br />
                medcare-solutions
              </span>{" "}
              <br />
              <span style={{ color: "white" }}>
                We will be in touch shortly.
              </span>{" "}
              <br />
            </h3>{" "}
            <br />
            <Button
              onClick={this.handleCloseSuccessModal}
              style={{
                margin: "auto",
                height: "2.9em",
                fontFamily: "Nunito",
                fontSize: "1rem",
                fontWeight: 500,
                borderRadius: "5em",
                backgroundColor: "white",
                color: "black",
                border: "none",
                marginBottom: "1em",
                paddingLeft: "3em",
                paddingRight: "3em",
                marginRight: "0em",
                textShadow: "none",
              }}
              type="primary"
            >
              Close
            </Button>{" "}
          </SuccessModal>
          {/* <SuccessModal
            open={this.state.notifModal}
            onClose={this.handleNotifModal}
            center
          >
            <img src={NoticeModal} alt="Logo" style={{ width: "100%", margin: "auto" }} />


          </SuccessModal> */}
          {/* <Link style={{}} to="/signin">
            Sign In
          </Link> */}
          <Footer />
        </Spin>
      </div>
    ) : (
      <div>
        <Spin spinning={this.state.loading}>
          <Header />
          <Row style={{ maxWidth: "90em", margin: "auto" }}>
            <Col sm={24} md={13} className="home-col">
              <Fade bottom>
                <h1
                  style={{
                    marginTop: "4rem",
                    fontWeight: 700,
                    fontSize: 90,
                    marginLeft: "0em",
                    color: "#00AEE9",
                  }}
                >
                  Medical Care
                </h1>{" "}
                <h1
                  style={{
                    fontWeight: 500,
                    letterSpacing: 5,
                    fontSize: 41,
                    marginTop: "-2em",
                    color: "#3D3D3D",
                  }}
                >
                  Simplified For Everyone
                </h1>{" "}
                <p
                  className="mulish-paragraph-small"
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    marginTop: "1rem",
                    marginBottom: "2rem",
                    paddingRight: "4em",
                    color: "#3D3D3D",
                  }}
                >
                  Good health is the state of mental, physical and social well being and it does not just mean absence of diseases.
                </p>{" "}
                <Fade bottom>
                  <div className="home-button">
                    <a href="#book">
                      <Button
                        className="btn-grad"
                        style={{
                          borderRadius: 10,
                          fontWeight: 500,
                          height: "4em",
                          width: "15em",
                          fontSize: "17px",
                          backgroundColor: "#00AEE9",
                          border: "black",
                          marginTop: "1em",
                        }}
                        type="primary"
                      >
                        Book an appointment
                        <span
                          style={{
                            paddingLeft: "0.5em",
                            fontSize: 18
                          }}
                        >
                          <ArrowDownOutlined />
                        </span>

                      </Button>
                    </a>
                    <span
                      onClick={this.handleOpenYearModal}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{ width: "5.5em", marginLeft: "2em" }}
                        src={QuoteImage}
                      />
                      <span
                        style={{
                          borderRadius: 10,
                          fontWeight: 500,
                          height: "4em",
                          color: "#00AEE9",
                          fontSize: "17px",
                          backgroundColor: "transparent",
                          border: "black",
                          marginTop: "1em",
                          marginLeft: "0.8em",
                          boxShadow: "none",
                        }}
                        type="primary"
                      >
                        Get a Quote
                      </span>
                    </span>
                  </div>
                </Fade>
              </Fade>
            </Col>
            <Col sm={24} md={11}
              style={{
                marginTop: "4em"
              }}
            >
              <img

                src={LandingBanner}
                alt="medcare-solutions-Banner"
              />
            </Col>

          </Row>
          <Row style={{ width: "100vw" }} />
          <Row
            id="about-us"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              marginTop: "10em",
            }}
          >
            <Col xs={24} md={12}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "-0em",
                    // color: "#004242",
                  }}
                >
                  Why Join Medcare Solutions?
                </p>
                <p
                  className="Mulish-center"
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "32px",
                    marginBottom: "-0em",
                    lineHeight: 1.3,
                    paddingRight: window.isPhone ? "0em" : "3em",
                  }}
                >
                  Medcare has the infrastructure to offer all our clients
                  professional & quality services.
                </p>
              </Fade>
            </Col>
            <Col xs={24} md={12}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontSize: 18,
                    fontWeight: 400,
                    marginTop: "1.5rem",
                    color: "black",
                    paddingRight: window.isPhone ? "1em" : "",
                    paddingLeft: window.isPhone ? "1em" : "",
                  }}
                >
                  More cover for everyone they love, more care for every time
                  they need us, more simplicity in a world of chaos and
                  confusion, and more value for every rand they spend.
                </p>{" "}
                <div
                  onClick={this.handleOpenYearModal}
                  style={{
                    cursor: "pointer",
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "-0em",
                    color: "rgb(0, 174, 233)",
                  }}
                >
                  <img
                    style={{
                      width: "2em",
                      marginLeft: "-0.4em",
                      marginRight: "0.3em",
                    }}
                    src={QuoteImage}
                  />
                  Get a quote Today
                </div>
              </Fade>
            </Col>
          </Row>
          <Row style={{ maxWidth: "90em", margin: "auto", marginTop: "5em" }}>
            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={
                      <p className="color-change">Exclusive Medcare member</p>
                    }
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          We ensure you get more for less. In addition to
                          receiving affordable, quality healthcare, being part
                          of the Medcare Solutions means you get to benefit from
                          offers that make your money go further.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>

            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={<p className="color-change">Client-focused</p>}
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          Our full suite of integrated services fulfills the
                          needs of major medical schemes, health plans and a
                          growing list healthcare clients. Medcare Solutions
                          end-to-end client-focused service offering provides
                          sound value within the highly complex and dynamic
                          healthcare environment.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>

            <Col xs={24} md={8} style={{ padding: "1em" }}>
              <Fade delay={200}>
                <Card
                  className="card-grad"
                  style={{
                    minHeight: "20em",
                    padding: "1em",
                    borderRadius: "1em",
                    border: "none",
                    textAlign: "left",
                    border: "0px solid lightgrey",
                  }}
                  // hoverable
                  cover={
                    <img
                      style={{
                        textAlign: "right",
                        width: "8.5em",
                        margin: "auto",
                        marginBottom: "-4em",
                        marginRight: 0,
                        marginTop: "1em",
                      }}
                      src={About1}
                    />
                  }
                >
                  <Meta
                    title={<p className="color-change">Medcare Solutions</p>}
                    description={
                      <span>
                        {" "}
                        <p
                          className="color-change"
                          style={{
                            lineHeight: "1.7em",
                            marginTop: "0.5rem",
                            color: "grey",
                          }}
                        >
                          As a fully accredited, full-service third party
                          administrator and managed care organisation with a
                          proven track record, we are established in the
                          provision of evidence-based, integrated healthcare
                          solutions aligned to national best practice.
                        </p>{" "}
                      </span>
                    }
                  />
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: window.isPhone ? "1em" : "5em",
              maxWidth: "90em",
              padding: "1em",
              textAlign: window.isPhone ? "center" : "",
            }}
          >
            <img
              style={{
                width: "100vw",
                position: "absolute",
                // top: "108em",
                left: 0,
                height: "60em",
                objectFit: "cover",
              }}
              src={AboutBackImage}
              alt="medcare-solutions-Banner"
            />
            <Col xs={0} md={1} />
            <Col xs={24} md={10} style={{ margin: "auto" }}>
              <Fade>
                <h1 style={{ marginTop: "2em", color: "black" }}>
                  The Medcare Promise
                  <br />
                </h1>
                <h1
                  style={{
                    fontSize: "40px",
                    color: "black",
                    lineHeight: "1em",
                  }}
                >
                  Your health is our number one highest priority
                  <br />
                </h1>
                <p
                  style={{
                    paddingRight: window.isPhone ? "0em" : "6em",
                    marginTop: "1.5em",
                    marginBottom: "1.5em",
                    fontSize: "16px",
                  }}
                >
                  Everyone deserve a better treatment for their health care,
                  including you and that is our main priority
                </p>
                <a href="#book">
                  <Button
                    // onClick={this.handleOpenYearModal}
                    className="btn-grad"
                    style={{
                      border: "1px solid #27aee7",
                      borderRadius: "3em",
                      fontSize: "15px",
                      fontWeight: 600,
                      backgroundColor: "#0E82B6",
                      color: "white",
                      height: "45px",
                      paddingTop: "0.2em",
                      paddingLeft: "2em",
                      paddingRight: "2em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "1em",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Get a Callback
                  </Button>
                </a>
              </Fade>
            </Col>
            <Col xs={24} md={12} style={{ textAlign: "center" }}>
              <Fade>
                <img
                  style={{}}
                  src={AboutImage}
                  alt="medcare-solutions-Banner"
                />
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: window.isPhone ? "1em" : "5em",
              maxWidth: "90em",
              padding: "1em",
              textAlign: window.isPhone ? "center" : "",
            }}
          >
            <Col xs={24} md={10} style={{ textAlign: "center" }}>
              <Fade>
                <img style={{}} src={DepImage} alt="medcare-solutions-Banner" />
              </Fade>
            </Col>
            <Col xs={0} md={1} />

            <Col xs={24} md={10}
              style={{
                margin: "auto",

              }}>
              <div
                style={{
                  borderTop: "7px solid #00AEE9",
                  width: "7em"
                }}
              />
              <Fade>
                <h1 style={{ marginTop: "1em", color: "black" }}>
                  Superior Service
                  <br />
                </h1>
                <h1
                  style={{
                    fontSize: "40px",
                    color: "black",
                    lineHeight: "1em",
                  }}
                >
                  We offer Superior Quality at a Great Price
                  <br />
                </h1>
                <p
                  style={{
                    marginTop: "1.5em",
                    fontSize: "16px",
                    marginBottom: "1.5em",
                  }}
                >
                  Each of our options offers affordably priced benefits. We
                  continuously review and improve the range of benefits, in each
                  option, to bring you what you really need – quality healthcare
                  at affordable prices and a range of plans that suit your
                  specific, individual needs.
                </p>
                <Button
                  onClick={this.handleOpenYearModal}
                  className="btn-grad"
                  style={{
                    border: "1px solid #27aee7",
                    borderRadius: "3em",
                    fontSize: "15px",
                    fontWeight: 600,

                    backgroundColor: "#0E82B6",
                    color: "white",
                    height: "45px",
                    paddingTop: "0.2em",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    marginTop: "0em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Let's Talk
                </Button>{" "}
              </Fade>
            </Col>
          </Row>
          <Row
            id="medical-care"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              textAlign: "center",
              marginTop: "4.5em",
              paddingBottom: "2em",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <h1 style={{ marginTop: "4em", color: "black" }}>
                Only the Best Medical Aid
              </h1>
              <h1
                style={{
                  margin: "auto",
                  marginTop: "-0.5em",
                  fontSize: "35px",
                  color: "black",
                }}
              >
                Our clients get quality cover from the best service providers
              </h1>
              <p
                style={{
                  fontSize: 18,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                We compare quotes from our range of suppliers
              </p>
              <p
                style={{
                  fontSize: 18,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                <span style={{ fontSize: "14px" }}>Starting price</span> <br />
                <span style={{ fontSize: "18px", fontWeight: 700 }}>
                  {" "}
                  Individual
                </span>{" "}
                - R1 252
                <br />
                <span style={{ fontSize: "18px", fontWeight: 700 }}>
                  {" "}
                  Family
                </span>{" "}
                - R3 066
              </p>
            </Col>
          </Row>
          <Row style={{ maxWidth: "70em", margin: "auto", marginTop: "2em" }}>
            <Col span={8} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "100%" : "70%",
                  marginTop: "-1.5em",
                }}
                src={BonitasImage}
              />
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={MomentumImage}
              />
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "100%" : "70%",
                  marginTop: "-0.8em",
                }}
                src={KingPriceImage}
              />
            </Col>
          </Row>
          <Row style={{ maxWidth: "95em", margin: "auto", marginTop: "3em" }}>
            <Col span={6} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={BestMedImage}
              />
            </Col>
            <Col span={6} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={FedhealthImage}
              />
            </Col>
            <Col span={6} style={{ textAlign: "center" }}>
              <img
                style={{
                  width: window.isPhone ? "100%" : "70%",
                  marginTop: "-2em",
                }}
                src={MediHelpImage}
              />
            </Col>
            <Col span={6} style={{ textAlign: "center" }}>
              <img
                style={{ width: window.isPhone ? "100%" : "70%" }}
                src={DiscoveryImage}
              />
            </Col>
          </Row>
          <Row
            id="about-us"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              marginTop: "6em",
            }}
          >
            <Col xs={24} md={18}>
              <Fade>
                <p
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "-0em",
                    // color: "#004242",
                  }}
                >
                  We'll come to you!
                </p>
                <p
                  className="Mulish-center"
                  style={{
                    textAlign: window.isPhone ? "center" : "",
                    fontWeight: 500,
                    fontSize: "32px",
                    marginBottom: "-0em",
                    lineHeight: 1.3,
                    paddingRight: window.isPhone ? "0em" : "3em",
                  }}
                >
                  We make it easy to schedule an appointment at any convenient
                  time & location.
                </p>
              </Fade>
            </Col>
            <Col xs={24} md={6}>
              <Fade>
                <Button
                  onClick={this.handleOpenYearModal}
                  className="btn-grad"
                  style={{
                    borderRadius: 10,
                    fontWeight: 500,
                    borderRadius: "3em",
                    height: "4em",
                    width: "15em",
                    fontSize: "17px",
                    backgroundColor: "#00AEE9",
                    border: "black",
                    margin: "auto",
                    marginTop: "3em",
                  }}
                  type="primary"
                >
                  Get your custom quote
                </Button>
              </Fade>
            </Col>
          </Row>
          <Row
            id="book"
            style={{
              width: "100vw",
              maxWidth: "90em",
              margin: "auto",
              textAlign: "center",
              marginTop: "4.5em",
              paddingBottom: "2em",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <h1 style={{ marginTop: "2em", color: "black" }}>
                We'll Contact You
              </h1>
              <h1
                style={{
                  margin: "auto",
                  marginTop: "-0.5em",
                  fontSize: "35px",
                  color: "black",
                }}
              >
                Get a Callback
              </h1>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "0.5rem",

                  color: "black",
                }}
              >
                Get Started with Medcare today!
              </p>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              maxWidth: "70em",
              textAlign: "center",
            }}
          >
            <img
              className="contact-image"
              style={{
                marginTop: "-15em",
                left: 0,
                zIndex: 0,
                position: "absolute",
              }}
              src={WingLeft}
            />
            <img
              className="hori-line"
              style={{
                marginTop: "-15em",
                right: 0,
                zIndex: 0,
                position: "absolute",
              }}
              src={WingRight}
            />{" "}
            <Col xs={24} md={24}>
              <ContactForm
                editStock={this.state.editStock}
                wrappedComponentRef={form => (this.vehiclesForm = form)}
              />
              <Button
                className="btn-grad"
                onClick={this.handleAddStock}
                style={{
                  borderRadius: "3em",
                  border: "1px solid #27aee7",
                  fontSize: "17px",
                  fontWeight: 600,

                  backgroundColor: "#0E82B6",
                  color: "white",
                  height: "55px",
                  paddingTop: "0.2em",
                  paddingLeft: "3em",
                  paddingRight: "3em",
                  textShadow: "none",
                  margin: "auto",
                  marginTop: "2em",
                }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>{" "}
            </Col>
          </Row>{" "}
          <SuccessModal
            open={this.state.buyYearModal}
            onClose={this.handleCloseYearModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "80em",
                    margin: "auto",
                    textAlign: "center",
                    // marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "white"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: this.state.signUpButtonVisible
                      ? "4em"
                      : "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div style={{ marginBottom: "3em" }}>
                          <img
                            className="header-logo"
                            style={{ width: "15em" }}
                            src={MainLogo}
                          />
                          <h1
                            style={{
                              marginTop: "2em",
                              fontSize: "15px",
                              color: "black",
                            }}
                          >
                            Only the Best Medical Aid
                          </h1>
                          <h1
                            style={{
                              margin: "auto",
                              marginTop: "-0.5em",
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            We'll get back to you with the best option available
                          </h1>
                        </div>
                      ) : (
                        <div style={{ marginBottom: "3em" }}>
                          <img
                            className="header-logo"
                            style={{ width: "15em" }}
                            src={MainLogo}
                          />
                          <h1
                            style={{
                              marginTop: "2em",
                              fontSize: "15px",
                              color: "black",
                            }}
                          >
                            Thank You for choosing Medcare Solutions
                          </h1>
                          <h1
                            style={{
                              margin: "auto",
                              marginTop: "-0.5em",
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            We'll be in touch shortly
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {this.state.signUpButtonVisible ? (
                    <StockForm
                      editStock={this.state.editStock}
                      wrappedComponentRef={form => (this.vehiclesForm = form)}
                    />
                  ) : null}
                  {this.state.signUpButtonVisible ? (
                    <ButtonRadius
                      onClick={this.handleAddStock}
                      className="btn-grad"
                      style={{
                        border: "1px solid #27aee7",
                        borderRadius: "3em",
                        fontSize: "15px",
                        fontWeight: 600,

                        backgroundColor: "#0E82B6",
                        color: "white",
                        width: "13em",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "2em",
                      }}
                      type="primary"
                      htmlType="submit"
                      block
                    >
                      Request Quote
                    </ButtonRadius>
                  ) : null}
                </div>
              </div>
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyWeekModal}
            onClose={this.handleCloseWeekModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> 12 WEEK PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockWeekForm
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyPremiumModal}
            onClose={this.handleClosePremiumModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> PREMIUM PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockFormPremium
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
            <h3
              style={{
                fontFamily: "Crimson Text",
                marginTop: "1em",
                color: "white",
                textAlign: "center",
              }}
            >
              Thank you for contacting
              <span>
                <br />
                medcare-solutions
              </span>{" "}
              <br />
              <span style={{ color: "white" }}>
                We will be in touch shortly.
              </span>{" "}
              <br />
            </h3>{" "}
            <br />
            <Button
              onClick={this.handleCloseSuccessModal}
              style={{
                margin: "auto",
                height: "2.9em",
                fontFamily: "Nunito",
                fontSize: "1rem",
                fontWeight: 500,
                borderRadius: "5em",
                backgroundColor: "white",
                color: "black",
                border: "none",
                marginBottom: "1em",
                paddingLeft: "3em",
                paddingRight: "3em",
                marginRight: "0em",
                textShadow: "none",
              }}
              type="primary"
            >
              Close
            </Button>{" "}
          </SuccessModal>
          {/* <SuccessModal
            open={this.state.notifModal}
            onClose={this.handleNotifModal}
            center
          >
            <img src={NoticeModal} alt="Logo" style={{ width: "100%", margin: "auto" }} />


          </SuccessModal> */}
          {/* <Link style={{}} to="/signin">
            Sign In
          </Link> */}
          <Footer />
        </Spin>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
