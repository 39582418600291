import { getToken } from "src/redux/selectors";

export function addRequisition(values) {
  if (values.parts[values.parts.length - 1].addButton) {
    values.parts.pop();
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/addrequisition",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add requisition" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveRequisition(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/saverequisition",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to edit requisition" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteRequisition(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/deleterequisition",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete requisition" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllRequisition() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findallrequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "REQUISITION_FIND_ALLREQUISITION",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findMyRequisition(make) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findmyrequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "REQUISITIONS_FIND_MYREQUISITIONS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findJobNumbers(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/getjobnumbers?value=" +
          value,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to get job numbers" });
        }
        return resolve(await res.json());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findRequisitionDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findrequisitiondetails?id=" +
          id,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "REQUISITIONS_FIND_REQUISITIONDETAILS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionRequisition() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findauctionrequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "REQUISITIONS_FIND_AUCTIONREQUISITIONS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDoneRequisition() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/finddealdonerequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "REQUISITIONS_FIND_DEALDONEREQUISITIONS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionWonRequisition() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findauctionwonrequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "REQUISITIONS_FIND_WONREQUISITIONS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionLostRequisition() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/findauctionlostrequisition",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "REQUISITIONS_FIND_LOSTREQUISITIONS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditRequisition(requisition) {
  return dispatch =>
    dispatch({
      type: "REQUISITIONS_SET_EDITREQUISITION",
      data: requisition,
    });
}

export function setRequisitionDetailsId(id) {
  return dispatch =>
    dispatch({
      type: "REQUISITIONS_SET_REQUISITIONDETAILSID",
      data: id,
    });
}

export function findVariants(model) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/cddata/findvariants?model=" +
          model,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_VARIANTS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears(variant) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/cddata/findyears?variant=" +
          variant,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_YEARS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/acceptoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/rejectoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/acceptadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
          "medcare-solutions/api/requisition/rejectadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}
