import React, { PureComponent } from "react";
import { Layout, Row, Col } from "antd";
import { withRouter, Link } from "react-router-dom";
import {
  FaCopy,
  FaSignOutAlt,
  FaUserFriends,
  FaBuilding,
  FaDashcube,
} from "react-icons/fa";
import { Cookies } from "react-cookie";
import LogoSmall from "../../static/logo-small.svg";
const signOut = s => {
  if (window.cordova) {
    window.FirebasePlugin.getToken(
      token => {
        window.pubnub.push.deleteDevice(
          {
            device: token,
            pushGateway: "gcm", // apns, gcm, mpns
          },
          function(status) {
            if (status.error) {
              console.log("operation failed w/ error:", status);
            }
          }
        );
      },
      error => {
        console.error(error);
      }
    );
  }

  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("basic");
  localStorage.clear();
  window.location.reload();
};
export class ResFooter extends PureComponent {
  signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: "gcm", // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log("operation failed w/ error:", status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("basic");
    localStorage.clear();
    window.location.reload();
  };

  exit = false;
  handleBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <Layout.Footer
        style={{
          zIndex: 5,
          width: "100%",
          position: "fixed",
          bottom: 0,
          background: "rgb(95, 93, 96)",
          lineHeight: "40px",
          padding: 0,
          height: "3.5em",
          boxShadow: "0px 11px 20px 0 rgba(0, 0, 0, 10)",
          display: window.isPhone ? "" : "none",
        }}
      >
        <Row type="flex" justify="space-around" style={{}}>
          <Col span={8} style={{ display: "flex" }}>
            <Link to="/admin/users" style={{ margin: "0.5em" }}>
              <div
                style={{
                  color: "white",
                  margin: "auto",
                  marginBottom: "1.2em",
                  display: "grid",
                  cursor: "pointer",
                  borderRadius: "0.3em",
                  // backgroundColor: this.state.shift ? "#009BDF" : "",
                }}
              >
                <FaUserFriends
                  style={{
                    margin: "auto",
                    padding: "0.2em",
                    fontSize: "20px",
                    width: "1.8em",
                    height: "1.8em",
                  }}
                />

                {/*<span
              style={{
                marginTop: -11,
                fontSize: 12,
              }}
            >
              Search
            </span>*/}
              </div>{" "}
            </Link>
          </Col>
          <Col span={4} style={{ display: "flex" }}>
            <Link to="/admin/stock" style={{ marginTop: "0.5em" }}>
              <div
                style={{
                  margin: "auto",
                  background: "rgb(95, 93, 96)",
                  borderRadius: "50%",
                  width: 60,
                  height: 60,
                  bottom: 20,
                  position: "relative",
                  display: "flex",
                  border: "4px solid white",
                }}
              >
                <img
                  // onClick={props.collapseSidebar}
                  src={LogoSmall}
                  alt="Logo"
                  style={{
                    margin: "auto",
                    color: "white",
                    padding: "0.2em",
                    fontSize: "30px",
                    width: "1.6em",
                    height: "1.6em",
                  }}
                />
              </div>
            </Link>
          </Col>

          <Col span={8} style={{ display: "flex" }}>
            <div
              onClick={this.signOut}
              style={{
                color: "white",
                margin: "auto",
                marginBottom: "1.7em",
                marginRight: 0,
                display: "grid",
              }}
            >
              <FaSignOutAlt
                style={{
                  margin: "auto",

                  padding: "0.2em",
                  fontSize: "20px",
                  width: "1.8em",
                  height: "1.8em",
                }}
              />
              {/*<span
              style={{
                marginTop: -11,
                fontSize: 12,
              }}
            >
              Profile
            </span>*/}
            </div>
          </Col>
        </Row>
      </Layout.Footer>
    );
  }
}

export default ResFooter;
