import React from "react";
import styled from "styled-components";
import { CheckOutlined, SearchOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
} from "antd";
import moment from "moment";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const numeral = require("numeral");
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
`;

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findMyRequest()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findMyRequest()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      },
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let request = this.state.request;
    if (order === "ascend") {
      request.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      request.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "black" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  onRequestApprove = request => {
    this.setState({ loading: true });
    request.status = "None";
    this.props
      .approveRequest(request)
      .then(() => {
        this.props
          .findMyRequest()
          .then(() => {
            this.props
              .sendNotification(
                request.userId,
                [request.userId],
                "Admin has approved one of your requests",
                "adminChanged",
                request._id,
                false,
                "medcare-solutions Admin"
              )
              .then(() => {
                this.setState({ loading: false });
                Notify("success", "Successfully approved request");
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  onRequestReject = request => {
    this.setState({ loading: true });
    request.status = "None";
    this.props
      .rejectRequest(request)
      .then(() => {
        this.props
          .findMyRequest()
          .then(() => {
            this.props
              .sendNotification(
                request.userId,
                [request.userId],
                "Admin has rejected one of your requests",
                "adminChanged",
                request._id,
                false,
                "medcare-solutions Admin"
              )
              .then(() => {
                this.setState({ loading: false });
                Notify("success", "Successfully rejected request");
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  render() {
    const { request } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col xs={24} md={12}>
            <CheckOutlined
              style={{ fontSize: 25, color: "#EC8144", marginRight: 20 }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>PEX Requests</span>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "flex-end" }}
            xs={24}
            md={12}
          />
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <StyledTable
                style={{ background: "white" }}
                rowKey="_id"
                size="middle"
                pagination={{
                  defaultPageSize: 25,
                }}
                expandedRowRender={
                  window.innerWidth <= 420
                    ? record => (
                        <Card
                          size="small"
                          title={record.itemCode}
                          style={{ width: 300, marginLeft: -35 }}
                        >
                          <p>Brand: {record.brand}</p>
                          <p>Model: {record.model}</p>
                          <p>Job Number: {record.jobNumber}</p>
                          <p>Description: {record.description}</p>
                          <p>Serial: {record.serial}</p>
                          <p>Type: {record.type}</p>

                          <p>Request Timestamp: {record.timestamp}</p>
                        </Card>
                      )
                    : null
                }
                dataSource={this.props.request}
                onChange={this.handleSort}
              >
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("brand")}
                    title="Brand"
                    dataIndex="brand"
                    key="brand"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("model")}
                    title="Model"
                    dataIndex="model"
                    key="model"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("jobNumber")}
                    title="Job Number"
                    dataIndex="jobNumber"
                    key="jobNumber"
                  />
                ) : null}
                <Column
                  {...this.getColumnSearchProps("description")}
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("serial")}
                    title="Serial No."
                    dataIndex="serial"
                    key="serial"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("type")}
                    title="Type"
                    dataIndex="type"
                    key="type"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("site")}
                    title="Site"
                    dataIndex="site"
                    key="site"
                  />
                ) : null}

                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps("timestamp")}
                    title="Request Timestamp"
                    dataIndex="timestamp"
                    key="timestamp"
                  />
                ) : null}
                <Column
                  title="Status"
                  render={(text, request) =>
                    request.approved === undefined ? (
                      <span style={{ color: "orange" }}>Pending Request</span>
                    ) : request.approved ? (
                      <span style={{ color: "green" }}>Approved</span>
                    ) : (
                      <span style={{ color: "red" }}>Rejected</span>
                    )
                  }
                />
              </StyledTable>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default Request;
