import React, { Component } from "react";
import { Layout, Icon, Row, Col, Form, Input, Button, Modal } from "antd";

import LogoModal from "../../../static/Home/main-logo.svg";
import Facebook from "../../../static/Home/facebook.svg";
import Insta from "../../../static/Home/insta.svg";
import Whatsapp from "../../../static/Home/whatsapp.svg";
import FooterBackBlack from "../../../static/Home/footer-back-black.png";
import FooterLogo from "../../../static/Home/logo-white.png";
import { Modal as Succesxsodal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import styled from "styled-components";
const StyledModal = styled(Modal)`
  width: 70vw !important;
  margin-top: -5em;
  .ant-modal {
    width: 80vw !important;
  }
  .ant-modal-close-x {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background-color: black;
    border-radius: 3em;
    display: block;
    width: 40px !important;
    height: 40px !important;
    font-size: 20px !important;
    color: white !important;
    font-style: normal;
    line-height: 38px !important;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-mask {
    background-color: white !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .react-responsive-modal-modal {
    background-color: transparent !important;
  }
  .ant-modal-content {
    margin: auto;

    background-color: white !important;
  }
  .ant-modal-body {
    margin: auto;
    width: 50vw;
    overflow: hidden;
    padding-left: 0em;
    background-color: transparent !important;
  }
  .react-responsive-modal-closeButton {
    display: none;
  }
  .react-responsive-modal-closeButton {
    border: none !important;
  }
  .ant-modal-centered .ant-modal {
    width: 99vw !important;
    background-color: transparent !important;
  }
`;
if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "#F3F3F3",
          padding: "1em",
          // marginTop: window.isPhone ? "" : "6.5em",
          // backgroundImage: `url(${FooterBackBlack})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginTop: window.isPhone ? "3em" : "3em",
        }}
      >
        <div
          className="footer-div"
          id="footer"
          style={{
            margin: "auto",
            marginTop: window.isPhone ? "0em" : "0.5em",
            backgroundColor: "transparent",
            maxWidth: "100em",
            zIndex: 999,
            paddingTop: "0em",
            borderTop: "0px solid lightgrey",
            borderRadius: "0em 0em 0em 0em",
          }}
        >
          <Row
            justify="center"
            className="footer"
            style={{
              margin: "auto",
              paddingTop: window.isPhone ? "" : "1.9em",
              zIndex: 999,
              maxWidth: "100em",
              height: window.isPhone ? "" : "16.5em",
              textAlign: window.isPhone ? "center" : "left",
            }}
          >
            <Col
              xs={24}
              md={6}
              style={{
                textAlign: window.isPhone ? "center" : "left",
                marginTop: "3em",
              }}
            >
              <img
                src={FooterLogo}
                alt="Logo"
                style={{
                  boxShadow: "none",
                  width: "14em",
                }}
              />{" "}
            </Col>{" "}
            <Col
              xs={24}
              md={6}
              style={{
                textAlign: window.isPhone ? "center" : "left",
                marginTop: "3em",
              }}
            >
              <p
                className="Mulish-footer"
                style={{
                  fontSize: 14,

                  paddingRight: window.isPhone ? "" : "4em",
                  color: "black",
                }}
              >
                We are committed to enhancing the benefits that we offer across
                all our plans, ensuring that you receive high-quality services
                that are affordable and tailored to meet your unique needs.
              </p>{" "}
            </Col>{" "}
            <Col
              xs={24}
              md={6}
              style={{
                textAlign: window.isPhone ? "center" : "left",
                marginTop: "3em",
              }}
              className="footer-links-company"
            >
              <h1
                style={{
                  fontSize: 15,

                  lineHeight: "0.8em",
                  color: "black",
                }}
              >
                Times
              </h1>{" "}
              <span
                style={{
                  lineHeight: "1.8em",
                  fontWeight: 400,

                  color: "black",
                  fontSize: "1rem",
                  textDecorationcolor: "black",
                }}
              >
                Mon - Sat : 09:00 AM - 07:00 PM
                <br />
                Sunday : Closed
              </span>{" "}
              <br />
            </Col>
            <Col
              xs={24}
              md={6}
              style={{
                textAlign: window.isPhone ? "center" : "left",
                marginTop: "3em",
              }}
              className="footer-links-company"
            >
              <h1
                style={{
                  fontSize: 15,

                  lineHeight: "0.8em",
                  color: "black",
                }}
              >
                GET IN TOUCH
              </h1>
              <div
                style={{
                  margin: "auto",
                  textAlign: window.isPhone ? "center" : "left",
                }}
                className="footer-links-company"
              >
                <a
                  style={{
                    lineHeight: "1.8em",
                    fontWeight: 400,

                    color: "black",
                    fontSize: "1rem",
                    textDecorationcolor: "black",
                  }}
                  href="mailto:ruan@medcaresolutions.co.za"
                  target="_blank"
                >
                  ruan@medcaresolutions.co.za
                </a>
                <br />
                <a
                  style={{
                    lineHeight: "1.8em",
                    fontWeight: 400,

                    color: "black",
                    fontSize: "1rem",
                    textDecorationcolor: "black",
                  }}
                  href="mailto:admincare@medcaresolutions.co.za"
                  target="_blank"
                >
                  admincare@medcaresolutions.co.za
                </a>
                <br />
                <a
                  style={{
                    lineHeight: "1.8em",
                    fontWeight: 400,

                    color: "black",
                    fontSize: "1rem",
                    textDecorationcolor: "black",
                  }}
                  href="tel:+27844123310"
                  target="_blank"
                >
                  +27 84 412 3310
                </a>
                <br />
                <a
                  href="https://www.instagram.com/moniqinteriors/"
                  target="_blank"
                >
                  {/*<img
                    src={FBMessage}
                    alt="Logo"
                    style={{
                      marginTop:"1em",
                      marginTop: "2.5em",
                      marginRight: "2em",
                      boxShadow: "none",
                    }}
                  />{" "}*/}
                </a>
                <a
                  href="https://www.instagram.com/moniqinteriors/"
                  target="_blank"
                >
                  {/*<img
                    src={Slack}
                    alt="Logo"
                    style={{
                      marginTop:"1em",
                      marginTop: "2.5em",
                      marginRight: "2em",
                      boxShadow: "none",
                    }}
                  />{" "}*/}
                </a>
                {/* <a
                  href="https://www.facebook.com/BCCconceptconstruction/"
                  target="_blank"
                >
                  <img
                    src={Facebook}
                    alt="Logo"
                    style={{
                      marginTop: "1em",

                      marginRight: "1em",
                      boxShadow: "none",
                    }}
                  />{" "}
                </a>
                <a href="whatsapp://send?phone=+27844123310" target="_blank">
                  <img
                    src={Whatsapp}
                    alt="Logo"
                    style={{
                      marginTop: "1em",
                      boxShadow: "none",
                    }}
                  />{" "}
                </a> */}
              </div>
              <a href="tel:+27844123310" style={{ color: "white" }}>
                <span className="btn get-in-touch">
                  <i className={"fa fa-phone"} /> CONTACT US
                </span>
              </a>
            </Col>
            <Row
              style={{
                margin: "auto",
                textAlign: "center",
                display: window.isPhone ? "grid" : "none",
                backgroundColor: "#434343",
                height: "6em",
                width: "100vw",
                paddingTop: "0.7em",
                marginLeft: "-1em",
                marginTop: "2em",
                marginBottom: "-1em",
              }}
            >
              <p
                style={{
                  maxWidth: "120em",
                  width: "100vw",
                  margin: "auto",
                  fontSize: "11px",
                  fontWeight: 400,
                  lineHeight: "0em",
                  marginTop: "1.5em",
                  color: "lightgrey",
                }}
              >
                <span style={{}}>
                  {/* <a
                    href="https://www.facebook.com/medcaresolutions/"
                    target="_blank"
                  >
                    <img
                      src={Facebook}
                      alt="Logo"
                      style={{
                        marginTop: "0em",
                        marginRight: "1em",
                        boxShadow: "none",
                      }}
                    />{" "}
                  </a> */}
                  <a href="whatsapp://send?phone=+27844123310" target="_blank">
                    <img
                      src={Whatsapp}
                      alt="Logo"
                      style={{
                        marginTop: "0em",
                        boxShadow: "none",
                      }}
                    />{" "}
                  </a>
                </span>
                <br />
                <p style={{ paddingTop: "1.5em" }}>
                  2024 © Medcare Solutions —
                  FSP:52267 — Crafted by{" "}

                  <a
                    style={{ color: "lightgrey" }}
                    href="https://squatchwebsites.com/"
                    target="_blank"
                  >
                    Squatch Websites
                  </a>
                </p>
              </p>{" "}
            </Row>
          </Row>
        </div>
        <Row
          style={{
            margin: "auto",
            textAlign: "center",
            display: window.isPhone ? "none" : "grid",
            backgroundColor: "#434343",
            height: "4em",
            width: "100vw",
            paddingTop: "0.7em",
            marginLeft: "-1em",
            marginBottom: "-1em",
          }}
        >
          <p
            style={{
              maxWidth: "120em",
              width: "100vw",
              margin: "auto",
              fontSize: "11px",
              fontWeight: 400,
              lineHeight: "0em",
              marginTop: "1.5em",
              color: "lightgrey",
            }}
          >
            <span style={{ float: "left" }}>
              2024 © Medcare Solutions —
              FSP:52267 — Crafted by{" "}
              <a
                style={{ color: "lightgrey" }}
                href="https://squatchwebsites.com/"
                target="_blank"
              >
                Squatch Websites
              </a>
            </span>
            <span style={{ float: "right" }}>
              {/* <a
                href="https://www.facebook.com/medcaresolutions/"
                target="_blank"
              >
                <img
                  src={Facebook}
                  alt="Logo"
                  style={{
                    marginTop: "-1em",
                    marginRight: "1em",
                    boxShadow: "none",
                  }}
                />{" "}
              </a> */}
              <a href="whatsapp://send?phone=+27844123310" target="_blank">
                <img
                  src={Whatsapp}
                  alt="Logo"
                  style={{
                    marginTop: "-1em",
                    boxShadow: "none",
                  }}
                />{" "}
              </a>
            </span>
          </p>{" "}
        </Row>
      </div>
    );
  }
}
export default Footer;
